@import '~antd/dist/antd.less';

@font-family: -apple-system, BlinkMacSystemFont, Roboto, 'Segoe UI', 'Consolas', 'Helvetica Neue', Arial, 'Noto Sans',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
//@primary-color: #389e0d;
//@text-color: rgba(0, 0, 0, 0.8);
//@error-color: #389e0d;
//@link-color: #389e0d;;

body {
    background-image: url('./media//scattered-forcefields.svg'); //background: linear-gradient(111.68deg, rgb(242, 236, 242) 0%, rgb(232, 242, 246) 100%);
    background-repeat: no-repeat;
    background-size: auto;
}

.dots {
    width: 3.5em;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
}

.dots div {
    width: 0.8em;
    height: 0.8em;
    border-radius: 50%;
    background-color: #ED4B9E;
    animation: fade 0.8s ease-in-out alternate infinite;
}

.dots div:nth-of-type(1) {
    animation-delay: -0.4s;
}

.dots div:nth-of-type(2) {
    animation-delay: -0.2s;
}

@keyframes fade {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
